import { NavLink } from "react-router-dom";
import AppLayout from "../../components/AppLayout";
import Preload from "./Preload";

export default function Home() {
  return (
    <>
      <AppLayout backgroundImg="/img/scene11_factory_manu01c.png">
        <div className="container">
          <div className="col-sm-8">
            <div
              style={{
                marginTop: "20px",
                padding: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.66)",
                width: "100%",
                textAlign: "left",
              }}
            >
              <h1>3DEXPERIENCE Game</h1>
              <div className="" style={{ fontWeight: "500" }}>
                <p>
                  Hola, ¡queremos invitarte a participar en el juego 3DEXPERIENCE!
                </p>
                <p>
                  El mundo entero se ha visto afectado por los acontecimientos de los últimos dos años,
                  por lo que hoy más que nunca las empresas necesitan adaptarse a la nueva normalidad
                  y las nuevas demandas del mercado con mayor
                  rapidez y eficiencia.
                </p>
                <p>
                  Participe en el juego 3DEXPERIENCE, una actividad dinámica, rápida e interactiva y,
                  a través de ella, obtenga información sobre el perfil de su empresa.
                  Al finalizar, recibe un diagnóstico de cómo se encuentra tu empresa
                  actualmente en términos de productividad e innovación
                  en comparación con empresas del mismo sector, 
                  además de recomendaciones de los consultores de Dassault Systèmes.
                </p>
                <p>¡Rellena el formulario y disfruta del juego 3DEXPERIENCE!</p>
              </div>
              <div style={{ padding: "5px" }}>
                <NavLink className="btn btn-primary" to={"/simulation"}>
                  Iniciar
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
      <Preload />
    </>
  );
}
