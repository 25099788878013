import enrollmentState from "../services/Enrollment";
import { NINE_BOX } from "../services/NineBoxAnalysis";
import Echart from "./Echart";

const mark_areas: any[] = NINE_BOX.map((nb) => ({
  type: "scatter",
  name: nb.name,
  markArea: {
    itemStyle: {
      color: nb.color,
    },
    label: {
      position: [10, 10],
    },
    data: [
      [
        {
          name: nb.name,
          coord: [nb.xMin, nb.yMin],
        },
        {
          coord: [nb.xMax, nb.yMax],
        },
      ],
    ],
  },
}));

export default function NineBox(props: {
  points: number[][];
  autoAverage?: boolean;
  legendText?: string;
}) {
  const { points, autoAverage, legendText = "Você" } = props;
  const series = [...mark_areas];
  if (autoAverage === true) {
    console.error("Please implement auto average");
  }
  series.push({
    name: "Você",
    symbolSize: 20,
    data: points,
    type: "scatter",
  });
  if (enrollmentState.globalAverage && series.length === 10) {
    console.log([...enrollmentState.globalAverage]);
    series.push({
      name: "Média Global",
      symbolSize: 20,
      data: [enrollmentState.globalAverage],
      type: "scatter",
    });
  }
  return (
    <Echart
      height="500px"
      options={{
        legend: {
          data: [legendText, "Média Global"],
        },
        grid: {
          left: "20",
          right: "70",
          bottom: "10",
          containLabel: true,
        },
        xAxis: {
          name: "Inovação",
          position: "bottom",
          max: 1.0,
        },
        yAxis: {
          name: "Produtividade",
          max: 1.0,
        },
        series: series,
      }}
    />
  );
}
