import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import AppLayout from "../../components/AppLayout";
import NineBox from "../../components/NineBox";
import enrollmentState from "../../services/Enrollment";
import { lastQuestion } from "../../services/Question";
import CompleteAnswersTable from "./CompleteAnswersTable";
import NineboxDescription from "./ninebox-description";

export default observer(function SceneTalksComplete() {
  const point = [enrollmentState.xValue, enrollmentState.yValue];
  useEffect(() => {
    document.body.setAttribute("class", "");
  });
  return (
    <AppLayout>
      <div className="container" style={{ height: "100%" }}>
        <h1>Felicidades</h1>
        <p className="lead">¡Has completado la simulación!</p>
        <button className="btn btn-secondary" onClick={lastQuestion}>
          &laquo; Volver
        </button>
        {/*
                    <p className="lead"><button className="btn btn-secondary" onClick={startOver}>Fazer de Novo</button></p>
                    */}
        <NineBox points={[point]} />
        <div style={{ minHeight: "500px" }}>
          <NineboxDescription point={point} />
        </div>
        <CompleteAnswersTable enrollment={enrollmentState.enrollment!} />
      </div>
    </AppLayout>
  );
});
