export default function BaixaProdutividadeAltaInovacao(){
    return (
      <div>
        <h1 className="display-1">Baixa Produtividade, Alta Inovação</h1>
        <p className="lead">
        Baseado nas decisões tomadas durante o jogo, sua empresa tem perdido 
        diversos negócios por dificuldade em atender todas as demandas atuais 
        do mercado em termos de produtividade. <br />
        Porém, o alto investimento na inovação de seus processos, indicada 
        pelas suas ações durante jogo, indica que seus custos atuais para 
        manter essa produtividade são bem dimensionados e demonstram 
        sustentabilidade em seus negócios. <br />
        Além disso, a entrada em novos mercados pode estar vinculada a 
        capacidade de inovação e a capacidade de produzir conforme as demandas dos seus clientes.
        </p>
      </div>
    );
}