import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q1",
  characterName: "Fernando - CEO",
  sceneNumber: 2,
  context: [
    "Señores, como saben, en los últimos tiempos hemos perdido clientes importantes y estamos buscando nuevas oportunidades.",
    "Necesitamos establecer una forma de comunicarnos de manera efectiva y rápida para responder a las oportunidades emergentes.",
  ],
  content: ["Me gustaría saber cómo estamos haciendo esto actualmente."],
  options: [
    {
      text: "Nos reunimos con el equipo semanalmente para actualizar las oportunidades.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "La reunión del equipo para discusiones es muy importante, pero el uso de una herramienta de comunicación puede llevar información y decisiones en tiempo real a todos los colaboradores necesarios y reducir la actividad de reunión para actualizaciones y definiciones sobre la empresa o proyecto.",
    },
    {
      text: "Contamos con un grupo de Whatsapp que facilita la interacción y comunicación entre el equipo.",
      xValue: 0.75,
      yValue: 0.5,
      feedbackContent:
        "El uso de herramientas de comunicación entre el equipo, como Whatsapp y E-mail, ya es un paso adelante, pero sería interesante adoptar herramientas que se integren con su proceso de desarrollo de productos.",
    },
    {
      text: "Intercambiamos correos electrónicos con cada nueva oportunidad que surge.",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "El uso de herramientas de comunicación entre el equipo, como Whatsapp y E-mail, ya es un paso adelante, pero sería interesante adoptar herramientas que se integren con su proceso de desarrollo de productos.",
    },
    {
      text: "Disponemos de una herramienta dedicada que facilita la comunicación, colaboración y gestión de nuevas oportunidades.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title: "¿Qué herramienta usas? (Escribe)",
      },
      feedbackContent:
        "Tener una buena comunicación y colaboración en equipo es sumamente importante, ya que evita dudas en la realización de la tarea y todos estarán al tanto del avance del proyecto.",
    },
  ],
} as IQuestion;
