import {
  hasNextButton,
  hasPreviousButton,
  nextQuestion,
  previousQuestion,
  questionState,
} from "../../services/Question";
import ButtonsWrapper from "./ButtonsWrapper";

export default function QuestionButtons() {
  return (
    <ButtonsWrapper>
      {hasPreviousButton() ? (
        <button className="btn btn-secondary" onClick={previousQuestion}>
          &laquo; Regressar
        </button>
      ) : null}

      <button
        className="btn btn-primary"
        onClick={() => {
          questionState.answerOption = undefined;
          questionState.setOptionsVisible(true);
        }}
      >
        Responder
      </button>
      {hasNextButton() ? (
        <button className="btn btn-primary" onClick={nextQuestion}>
          Ventaja &raquo;
        </button>
      ) : null}
    </ButtonsWrapper>
  );
}
