import { observer } from "mobx-react-lite";
import AppLayout from "../../components/AppLayout";
import { answerQuestion, questionState } from "../../services/Question";
import { IQuestionOption } from "../../types/QuestionInterface";
import ButtonsWrapper from "./ButtonsWrapper";
import QuestionOption from "./QuestionOption";
import QuestionOptionExtension from "./QuestionOptionExtension";

export default observer(function QuestionOptions(props: {
  options: IQuestionOption[];
}) {
  const scene = questionState.scene;
  const answerOption = questionState.answerOption;
  const chooseOption = () => {
    if (!answerOption) return;
    if (answerOption.extension) {
      questionState.setExtensionVisible(answerOption.extension);
    } else {
      answerQuestion();
    }
  };
  return (
    <AppLayout backgroundImg={scene.optionsBackground}>
      {props.options.map((o, i) => (
        <QuestionOption key={i} scene={scene} option={o} index={i} />
      ))}
      <ButtonsWrapper>
        <button
          className="btn btn-secondary"
          onClick={() => questionState.setOptionsVisible(false)}
        >
          &laquo; Regresar
        </button>
        {questionState.answerOption ? (
          <button className="btn btn-primary" onClick={chooseOption}>
            Elegir
          </button>
        ) : null}
      </ButtonsWrapper>
      <QuestionOptionExtension />
    </AppLayout>
  );
});
