import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q6",
  context: [
    "El equipo comercial proyecta un crecimiento del 20% en la demanda de pedidos en 3 meses.",
  ],
  content: [
    "¿Cómo podemos garantizar que estaremos preparados para absorber este crecimiento en tan poco tiempo?",
    "¿Podemos evaluar si hay mano de obra, insumos y recursos disponibles?",
  ],
  options: [
    {
      text: "Podemos hacer un análisis, pero tomará algunos días completarlo y presentar un resultado para la discusión.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Para predecir el posible crecimiento del mercado, no se puede depender de análisis que toman tiempo en completarse, esto puede generar una brecha en relación con las tendencias emergentes, ya que las empresas que obtienen información en tiempo real sobre la demanda, la dirección del mercado y los nuevos productos que llegan, terminan tomando la iniciativa en el desarrollo y preparación de su equipo.",
    },
    {
      text: "Sí, nuestro ERP nos ayuda a proyectar la compra de insumos, pero tendremos que simular la capacidad de mano de obra y maquinaria en planillas auxiliares para analizar si tendremos sobrecargas.",
      xValue: 0.35,
      yValue: 0.35,
      feedbackContent:
        "Para predecir el posible crecimiento del mercado, la ayuda de un ERP significa un gran paso, pero no llena todos los vacíos para tener una visión completa. Adherirse a una solución que permita crear varios escenarios posibles puede preparar a todo el equipo para soportar eventuales picos de demanda, sin sorpresas.",
    },
    {
      text: "Sí, contamos con una solución que nos permite simular diferentes escenarios de crecimiento proyectando el uso de cada recurso requerido.",
      xValue: 0.8,
      yValue: 0.8,
      feedbackContent:
        "Predecir y simular situaciones de crecimiento de la demanda es fundamental para una empresa, estar al tanto de las tendencias es el resultado de herramientas que brindan la información correcta para utilizar los recursos de la mejor manera.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-sala.jpg",
  characterName: "Antonio - Gerente de Planificación",
  sceneNumber: 1,
} as IQuestion;
