export default function AltaProdutividadeMediaInovacao(){
    return (
      <div>
        <h1 className="display-1">Alta Produtividade, Média Inovação</h1>
        <p className="lead">
        Baseado nas decisões tomadas durante o jogo, sua empresa tem atendido 
        as demandas atuais do mercado em termos de produtividade. <br />
        Porém, o baixo investimento na inovação de seus processos, indicada 
        pelas suas ações durante jogo, levanta a possibilidade de que seus 
        custos atuais para manter essa alta produtividade podem estar um pouco 
        acima do que deveriam. <br />
        Além disso, a entrada em novos mercados pode 
        estar vinculada a capacidade de inovação de seus processos.
        </p>
      </div>
    );
}