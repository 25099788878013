import { NavLink } from "react-router-dom";

export default function ReportsHome(){

    return (
      <div className="container">
        <h1 className="display-1">Informes</h1>
        <div className="list-group">
          <NavLink
            to="./enrollments"
            className="list-group-item list-group-item-action"
          >
            Inscripciones
          </NavLink>
          <NavLink
            to="./questions"
            className="list-group-item list-group-item-action"
          >
            Distribución de Respuestas
          </NavLink>
          <NavLink
            to="./possible-answer-distribution"
            className="list-group-item list-group-item-action"
          >
            Distribución de probabilidad de las respuestas
          </NavLink>
        </div>
      </div>
    );
}