import { observer } from "mobx-react-lite";
import AppLayout from "../../components/AppLayout";
import Authentication from "../authentication/AuthenticationWall";
import MyEnrollments from "./MyEnrollments";
import SideNav from "./SideNav";

export default observer(function Profile() {
    return (
        <Authentication>
            <AppLayout justifyContent="flex-start">
                <div className="container">
                    <hr />
                    <h1>Mi perfil</h1>
                    <hr />
                    <div className="row">
                        <div className="col-sm-3">
                            <SideNav />
                        </div>
                        <div className="col-sm-9">
                            <MyEnrollments />
                        </div>
                    </div>
                </div>
            </AppLayout>
        </Authentication>
    );
})