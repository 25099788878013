export default function AltaProdutividadeAltaInovacao(){
    return (
      <div>
        <h1 className="display-1">Alta productividad, Alta innovación</h1>
        <p className="lead">
        Con base en las decisiones tomadas durante el juego, su empresa ha 
        demandas actuales del mercado en términos de productividad. <br />
        Además, la alta inversión en la innovación de sus procesos, 
        indicado por sus acciones durante el juego, indica que sus costos actuales 
        para mantener esta productividad están bien dimensionadas y demuestran 
        sostenibilidad en tu negocio. <br />
        Además, la entrada en nuevos 
        los mercados pueden estar vinculados a la capacidad de innovación de sus procesos.
        </p>
      </div>
    );
}