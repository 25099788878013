import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q9",
  context: [],
  content: [
    "Estoy iniciando un proyecto para redefinir nuestra infraestructura y me gustaría saber si el software que usas necesariamente debe instalarse en nuestros servidores físicos o si podríamos considerar usar estas soluciones en la nube.",
  ],
  options: [
    {
      text: "Las soluciones que utilizamos no tienen soporte en la nube, por lo que no creo que pueda ayudarte con esta iniciativa.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Las soluciones en la nube se han vuelto imprescindibles últimamente, y la tendencia es que el mercado adopte cada vez más herramientas como esta. Sin la necesidad de inversiones en hardware muy potente y servidores locales, aún garantiza un entorno que está completamente disponible en todo momento y 100% seguro.",
    },
    {
      text: "Actualmente usamos nuestros servidores locales para ejecutar nuestros sistemas principales y almacenar proyectos y no nos gustaría cambiar eso.",
      xValue: 0.0,
      yValue: 0.0,
      feedbackContent:
        "Las soluciones en la nube se han vuelto imprescindibles últimamente, y la tendencia es que el mercado adopte cada vez más herramientas como esta. Sin la necesidad de inversiones en hardware muy potente y servidores locales, aún garantiza un entorno que está completamente disponible en todo momento y 100% seguro.",
    },
    {
      text: "Las soluciones que usamos tienen soporte en la nube pero no tengo un plan definido para esta migración.",
      xValue: 0.25,
      yValue: 0.75,
      feedbackContent:
        "Realizar la migración a la Nube lo antes posible será un paso muy importante. Este cambio se traducirá en mucha más colaboración en equipo y, entre otras áreas, una comunicación limpia e información en tiempo real disponible en todo momento.",
    },
    {
      text: "Nuestras herramientas pueden ejecutarse en la nube y nuestro equipo está abierto a iniciar un proceso de migración, ¡considere nuestro departamento favorable a su proyecto!",
      xValue: 0.25,
      yValue: 1,
      feedbackContent:
        "Las empresas preparadas con herramientas en la nube se adelantan a la competencia, ya que entregan algo totalmente nuevo, una forma de trabajar diferente, pero muy funcional y ágil.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-scene-gray-room.jpg",
  characterName: "Felipe - Director de TI",
  sceneNumber: 10,
} as IQuestion;
