import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q10",
  context: [
    "Tenemos la oportunidad de ganar un gran cliente de nuestro principal competidor, sin embargo, un punto crucial para ellos es la validación virtual de algunos aspectos del uso de productos como: Esfuerzos Mecánicos y Comportamiento de Fluidos.",
  ],
  content: ["¿Podemos hacer este tipo de validación virtual?"],
  options: [
    {
      text: "Desafortunadamente no, actualmente solo hacemos cálculos manuales como parte de nuestro proceso de desarrollo.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Realizar validaciones manuales, además de ser una actividad muy compleja que puede requerir un retraso en la entrega del proyecto, el riesgo de falla es muy alto, lo que puede traer complicaciones y resultar en retrabajos. Actualmente es fundamental para ser competitivos en el mercado contar con herramientas de simulación, principalmente Esfuerzos Mecánicos y Comportamiento de Fluidos.",
    },
    {
      text: "Solo tenemos herramientas para simulaciones básicas. No pudimos alcanzar el nivel de complejidad requerido.",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "Es muy importante tener esta mentalidad innovadora y sostenible, pero lo ideal sería invertir en un nivel de precisión con simulaciones avanzadas sin necesidad de costes extra, teniendo el control de todo el proceso de desarrollo desde la creación del diseño, pruebas de durabilidad y calidad, hasta la entrega de este producto.",
    },
    {
      text: "Cuando es necesario, utilizamos un socio externo para manejar este tipo de demanda.",
      xValue: 0.25,
      yValue: 0.5,
      feedbackContent:
        "Es muy importante tener esta mentalidad innovadora y sostenible, pero lo ideal sería invertir en un nivel de precisión con simulaciones avanzadas sin necesidad de costes extra, teniendo el control de todo el proceso de desarrollo desde la creación del diseño, pruebas de durabilidad y calidad, hasta la entrega de este producto.",
    },
    {
      text: "¡Sí! Podemos hacer todas las validaciones requeridas.",
      xValue: 1,
      yValue: 1,
      feedbackContent:
        "Las empresas que validan sus productos con simulación reducen absurdamente el costo con prototipos, alcanzan un alto nivel de sustentabilidad, ya que no generan residuos y no contaminan, reducen el tiempo de llegada del producto al mercado, sin mencionar la oportunidad de Disponer de informes sobre las características del producto.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-scene-gray-room.jpg",
  characterName: "Dolores - Director de ventas",
  sceneNumber: 11,
} as IQuestion;
