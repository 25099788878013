import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q5",
  context: [
    "El equipo comercial me llamó a una reunión para comentar que tenemos un cliente potencial en los Estados Unidos que es muy diferente a lo que estamos acostumbrados, pero extremadamente prometedor.",
    "Este cliente quiere traer un nuevo producto. En la discusión con el equipo comercial, teníamos la duda de si nuestro proceso actual requerirá la creación de varios prototipos físicos, de ser así, esto aumentará el valor de la propuesta debido a los costos generados",
  ],
  content: [
    "¿Podemos afrontar este proyecto sin necesidad de muchos prototipos físicos?",
  ],
  options: [
    {
      text: "Internamente trabajamos con cálculos y algunas empresas colaboradoras hacen los prototipos físicos. Habrá que consultarles para ver los costes.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "Para atender proyectos sin necesidad de muchos prototipos, es necesario entender que cuando se subcontrata, se vuelve un paso más complejo para mantener el estándar de calidad, la gestión del tiempo del proyecto y la certificación del cumplimiento de los requisitos, sin incluir el costo adicional por el servicio prestado.",
    },
    {
      text: "No, la única forma de ejecutar estas pruebas es hacer los prototipos físicos internamente.",
      xValue: 0,
      yValue: 0.25,
      feedbackContent:
        "La realización de prototipos físicos tiene un impacto directo en el desarrollo y sobre todo en la sustentabilidad, actualmente existen tecnologías para realizar simulaciones que ayudan en la verificación de requerimientos, reduciendo drásticamente la creación de prototipos, lo que eleva absurdamente el costo del proyecto y el tiempo de lanzamiento del producto. El mercado.",
    },
    {
      text: "Para evitar los altos costos de la creación de prototipos físicos, podemos subcontratar la creación de prototipos virtuales. Aún así, habrá un costo adicional y un mayor tiempo de diseño.",
      xValue: 0.5,
      yValue: 0.75,
      feedbackContent:
        "Para atender proyectos sin necesidad de muchos prototipos, es necesario entender que cuando se subcontrata, se vuelve un paso más complejo para mantener el estándar de calidad, la gestión del tiempo del proyecto y la certificación del cumplimiento de los requisitos, sin incluir el costo adicional por el servicio prestado.",
    },
    {
      text: "Tenemos una solución para desarrollar prototipos virtuales que reduce drásticamente el tiempo de diseño y la necesidad de prototipos físicos.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title:
          "¿Cuál es la solución utilizada para desarrollar prototipos virtuales?",
      },
      feedbackContent:
        "Tener una solución completa que se pueda diseñar y todas las simulaciones (Estructurales, Fluidos, Impacto) aportará confiabilidad a la no construcción de un prototipo físico, cuanto más robusta la solución, el producto se desarrolla en menor tiempo y brinda compensación económica.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-entrada.jpg",
  characterName: "Melissa - Gerente de Ingeniería",
  sceneNumber: 6,
} as IQuestion;
