import { ITrack } from "../types/TrackInterface";

export const allTracks = [
  {
    title: "Equipo de ingeniería",
    description: ``,
    image: "/img/cafeteria/bg-engenharia.jpg",
    questions: [
      "3ds_q1",
      "3ds_q2",
      "3ds_q3",
      "3ds_q4",
      "3ds_q5",
      "3ds_q6",
      "3ds_q7",
      "3ds_q8-e",
      "3ds_q9-e",
      "3ds_q9",
      "3ds_q10",
      "3ds_q12",
    ],
  },
  {
    title: "Equipo de fabricación",
    description: ``,
    image: "/img/cafeteria/bg-manufatura-new.jpg",
    questions: [
      "3ds_q1",
      "3ds_q2",
      "3ds_q3",
      "3ds_q4",
      "3ds_q5",
      "3ds_q6",
      "3ds_q7",
      "3ds_q8",
      "3ds_q9",
      "3ds_q10",
      "3ds_q12",
    ],
  },
] as ITrack[];
