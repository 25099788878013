import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  UserCredential,
} from "firebase/auth";
import React, { ChangeEventHandler, ReactNode, useState } from "react";
import profileDatabase, { IProfile } from "../../database/ProfileDatabase";
import { auth } from "../../Firebase";

function TextField(props: {
  name: string;
  title: string;
  target: Record<string, string>;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
}) {
  const {
    disabled = false,
    type,
    placeholder,
    name,
    title,
    target,
    required,
  } = props;
  return (
    <>
      <div className="form-floating">
        <input
          type={type}
          disabled={disabled}
          className="form-control"
          required={required}
          id={name}
          placeholder={placeholder}
          onChange={(e) => (target[name] = e.target.value)}
          minLength={props.minLength}
          maxLength={props.maxLength}
        />
        <label htmlFor={name}>{title}</label>
      </div>
      <br />
    </>
  );
}

function Checkbox(props: {
  name: string;
  title: ReactNode;
  target: Record<string, string>;
  required?: boolean;
  disabled?: boolean;
}) {
  const { name, title, target, required, disabled } = props;
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    target[name] = e.target.checked ? "Sim" : "Não";
  };
  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value="Sim"
          id={name}
          name={name}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor={name}>
          {title}
        </label>
      </div>
    </>
  );
}

export default function Register() {
  const [state, setState] = useState({
    sending: false,
    error: "",
    success: "",
    target: { marketingAuthorized: "Não" } as Record<string, string>,
  });
  const { sending, error, success, target } = state;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setState({ sending: true, error: "", success: "", target: target });

    const { name, email, password, passwordConfirmation } = target;
    const {
      phone,
      company,
      position,
      zipCode,
      city,
      country,
      dataProcessAuthorized,
      marketingAuthorized,
    } = target;

    if (!email || !password) return;
    if (password !== passwordConfirmation) {
      setState({
        sending: false,
        error: "A confirmação não confere com a senha.",
        success: "",
        target: target,
      });
      return;
    }

    console.log("setei estado");
    createUserWithEmailAndPassword(auth, email, password)
      .then((credential: UserCredential) => {
        if (!credential.user) {
          setState({
            sending: false,
            error: "Usuario / contraseña invalida",
            success: "",
            target: target,
          });
        } else {
          updateProfile(credential.user, { displayName: name });
          const profile: IProfile = {
            id: credential.user.uid,
            name,
            email,
            phone,
            company,
            position,
            zipCode,
            city,
            country,
            role: "user",
            dataProcessAuthorized,
            marketingAuthorized,
          };
          profileDatabase.setProfile(profile);
          sendEmailVerification(credential.user)
            .then(() => {
              setState({
                sending: false,
                error: "",
                success:
                  "Se le ha enviado un enlace de confirmación por correo electrónico.",
                target: target,
              });
            })
            .catch((err) => {
              setState({
                sending: false,
                error: err.message || err.toString(),
                success: "",
                target: target,
              });
            });
        }
      })
      .catch((error2) => {
        setState({
          sending: false,
          error: error2.message || error2,
          success: "",
          target: target,
        });
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ paddingTop: "20px" }}>
        <TextField
          name="name"
          placeholder="Nombre Apellido"
          required
          target={target}
          title="Nombre*:"
          disabled={sending}
        />
        <TextField
          name="email"
          placeholder="nome@dominio.com"
          required
          target={target}
          title="Dirección de correo electrónico*:"
          type="email"
          disabled={sending}
        />
        <TextField
          name="phone"
          placeholder="(xx) 12345-1234"
          required
          target={target}
          title="Teléfono (ddd) + numero*:"
          type="text"
          disabled={sending}
          minLength={10}
          maxLength={30}
        />
        <TextField
          name="company"
          placeholder="Nombre de la empresa"
          required
          target={target}
          title="Nombre de la empresa*:"
          type="text"
          disabled={sending}
          minLength={2}
          maxLength={30}
        />
        <TextField
          name="position"
          placeholder="Ex: Gerente de la planta"
          required
          target={target}
          title="Oficina*:"
          type="text"
          disabled={sending}
          minLength={3}
          maxLength={30}
        />
        <TextField
          name="zipCode"
          placeholder="Ex: 12345678"
          required
          target={target}
          title="Código postal (solo números)*:"
          type="text"
          disabled={sending}
          minLength={8}
          maxLength={8}
        />
        <TextField
          name="city"
          placeholder="Ex: São Paulo"
          required
          target={target}
          title="Ciudad*:"
          type="text"
          disabled={sending}
          minLength={5}
          maxLength={8}
        />
        <TextField
          name="country"
          placeholder="Ex: Brasil"
          required
          target={target}
          title="País*:"
          type="text"
          disabled={sending}
          minLength={5}
          maxLength={30}
        />
        <TextField
          name="password"
          placeholder="[clave]"
          required
          target={target}
          title="Contraseña*:"
          type="password"
          disabled={sending}
          minLength={6}
          maxLength={30}
        />
        <TextField
          name="passwordConfirmation"
          placeholder="[misma clave]"
          required
          target={target}
          title="Confirme la contraseña*:"
          type="password"
          disabled={sending}
          minLength={6}
          maxLength={30}
        />
        <Checkbox
          name="dataProcessAuthorized"
          title={
            <span>
              Reconozco y acepto que mis datos personales se procesarán de acuerdo con la {" "}
              <a
                href="https://discover.3ds.com/es/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                política de privacidad
              </a>{" "}
              de 3DS y soy consciente de que, con fines comerciales, 3DS puede compartirlos con 
              partes interesadas de confianza (incluidos socios comerciales y patrocinadores de
              eventos de {" "}
              <a
                href="https://www.3ds.com/partners/locate-partner"
                target="_blank"
                rel="noreferrer"
              >
                Dassault Systèmes
              </a>{" "}          
              y {" "}
              <a
                href="https://www.solidworks.com/es/how-to-buy/find-solidworks-reseller/"
                target="_blank"
                rel="noreferrer"
              >
                SolidWorks
              </a>{" "}          
              , como se detalla en los formularios de inscripción).
            </span>
          }
          required
          disabled={sending}
          target={target}
        />
        <br />
        <Checkbox
          name="marketingAuthorized"
          title={
            <span>
              Acepto recibir comunicaciones de marketing sobre los productos o 
              servicios de 3DS.
            </span>
          }
          disabled={sending}
          target={target}
        />

        {sending ? (
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              style={{ width: "100%" }}
            />
          </div>
        ) : null}
        {error ? (
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Ooops!</h4>
            <p>{error}</p>
          </div>
        ) : null}
        {success ? (
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">Muy Bien!</h4>
            <p>{success}</p>
          </div>
        ) : null}
        <div style={{ padding: "20px 0px" }}>
          <button type="submit" className="btn btn-primary" disabled={sending}>
            Enviar
          </button>
        </div>
      </form>
    </>
  );
}
