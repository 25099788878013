import { sendPasswordResetEmail } from "firebase/auth";
import { useRef, useState } from "react";
import { auth } from "../../Firebase";

export default function PasswordResetEmail() {
    const [state, setState] = useState({
        sending: false,
        error: "",
        success: ""
    });
    const { sending, error, success } = state;
    const emailRef = useRef(null);
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const emailField: any = emailRef?.current;
        setState({ sending: true, error: "", success: "" });
        sendPasswordResetEmail(auth, emailField.value)
            .then(() => {
                setState({
                    sending: false,
                    error: "",
                    success: "Se ha enviado un enlace a su correo electrónico."
                });
            })
            .catch((error2) => {
                setState({
                  sending: false,
                  error: error2.message || error2,
                  success: "",
                });
            });
    }

    return (<>
        <form onSubmit={handleSubmit} style={{ paddingTop: "20px" }}>
            <h4>Olvidaste tu contraseña?</h4>
            <p>Le enviaremos un enlace por correo electrónico para que pueda ingresar una nueva contraseña.</p>
            <div className="form-floating">
                <input type="email" disabled={sending} className="form-control" required id="floatingInput" placeholder="name@example.com" ref={emailRef} />
                <label htmlFor="floatingInput">Dirección de correo electrónico*:</label>
            </div>
            <br />
            <div style={{ padding: "20px 0px" }}>
                <button type="submit" className="btn btn-primary" disabled={sending}>Reset</button>
            </div>
        </form>
        {
            sending ? <div className="progress">
                <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    style={{ width: "100%" }} />
            </div> : null
        }
        {
            error ? <div className="alert alert-danger" role="alert">
                <h4 className="alert-heading">Ooops!</h4>
                <p>{error}</p>
            </div> : null
        }
        {
            success ? <div className="alert alert-success" role="alert">
                <h4 className="alert-heading">Muy bien!</h4>
                <p>{success}</p>
            </div> : null
        }
    </>
    );
}