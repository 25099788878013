import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import ActionSuspense from "../../components/ActionSuspense";
import NineBox from "../../components/NineBox";
import enrollmentDatabase from "../../database/EnrollmentDatabase";
import profileDatabase, { IProfile } from "../../database/ProfileDatabase";
import { allQuestions } from "../../database/QuestionDatabase";
import { calcEnrollmentX, calcEnrollmentY } from "../../services/Enrollment";
import { IEnrollmentAnswer } from "../../types/EnrollmentInterface";
import usePromise from "../../utils/usePromise";
import NineboxDescription from "../simulation/ninebox-description";

function renderAnswer(answer: IEnrollmentAnswer, idx: number) {
  const question = allQuestions.find((q) => q.questionId === answer.questionId);
  return (
    <tr key={idx}>
      <td>
        <h4>{question?.characterName}</h4>
        <div>{question?.context?.join(" ")}</div>
        <div>&nbsp;</div>
        <h5>{question?.content.join(" ")}</h5>
      </td>
      <td>
        <p>{question?.options[answer.optionNumber].text}</p>
        <p>{answer.extensionText}</p>
        <p>{answer.extensionItems ? answer.extensionItems.join(", ") : ""}</p>
      </td>
      <td style={{ textAlign: "center" }}>{answer.xValue}</td>
      <td style={{ textAlign: "center" }}>{answer.yValue}</td>
      <td>{question?.options[answer.optionNumber].feedbackContent}</td>
    </tr>
  );
}

function RenderProfile(props: { email?: string }) {
  const { email } = props;
  const [profile, setProfile] = useState<IProfile>();
  useEffect(() => {
    if (email) profileDatabase.getProfile(email).then(setProfile);
  }, [email]);
  return profile ? (
    <>
      <h1 className="display-1">
        Participación <small className="display-6">{profile.name}</small>
      </h1>
      <table className="table">
        <tbody>
          <tr>
            <th style={{ width: "100px" }}>Empresa</th>
            <td>{profile.company}</td>
          </tr>
          <tr>
            <th>Oficina</th>
            <td>{profile.position}</td>
          </tr>
          <tr>
            <th>Ciudad</th>
            <td>
              {profile.city} - {profile.country} - CEP {profile.zipCode}
            </td>
          </tr>
          <tr>
            <th>E-mail</th>
            <td>
              <a href={`mailto:${profile.email}`}>{profile.email}</a>
            </td>
          </tr>
          <tr>
            <th>Teléfono</th>
            <td>
              <a href={`telto:${profile.phone}`}>{profile.phone}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  ) : null;
}

export default function EnrollmentView() {
  const { id } = useParams();
  const { loading, result, error } = usePromise(
    enrollmentDatabase.getEnrollment(id as string)
  );
  const point = result
    ? [calcEnrollmentX(result), calcEnrollmentY(result)]
    : [0, 0];

  const doNineBox =
    result?.status === "Completo" ? <NineBox points={[point]} /> : null;
  const doNineBoxText =
    result?.status === "Completo" ? <NineboxDescription point={point} /> : null;

  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/reports">Informes</NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink to="/reports/enrollments">Inscripciones</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {result?.email}
          </li>
        </ol>
      </nav>
      <RenderProfile email={result?.email} />
      <hr />
      <ActionSuspense loading={loading} error={error}>
        {result ? (
          <>
            <h2>{result.trackName}</h2>
            {doNineBox}
            {doNineBoxText}
            <div style={{ pageBreakAfter: "always" }} />
            <table className="table">
              <thead>
                <tr>
                  <th
                    style={{
                      width: "33%",
                      position: "sticky",
                      top: "0px",
                      backgroundColor: "#fff",
                    }}
                  >
                    Contexto/Pregunta
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      top: "0px",
                      backgroundColor: "#fff",
                    }}
                  >
                    Respuesta
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      top: "0px",
                      backgroundColor: "#fff",
                    }}
                  >
                    Innovación
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      top: "0px",
                      backgroundColor: "#fff",
                    }}
                  >
                    Productividad
                  </th>
                  <th
                    style={{
                      width: "33%",
                      position: "sticky",
                      top: "0px",
                      backgroundColor: "#fff",
                    }}
                  >
                    Feedback
                  </th>
                </tr>
              </thead>
              <tbody>{result.answers.map(renderAnswer)}</tbody>
            </table>
          </>
        ) : (
          <div className="alert alert-danger">
            No pudimos encontrar una inscripción con id {id}
          </div>
        )}
      </ActionSuspense>
    </div>
  );
}
