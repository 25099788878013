import { useState } from "react";
import Panels from "../../components/Panels";
import LoginWithEmailPassword from "./LoginWithEmailPassword";
import PasswordResetEmail from "./PasswordResetEmail";
import Register from "./Register";


export default function Login(props: { selected?: number }) {
    const [panel, setPanel] = useState(props.selected || 0);
    return (
        <div className="container">
            <hr />
            <h1>Entrar</h1>
            <hr />
            <div className="row">
                <div className="col-sm">
                    <div>
                        <p className="lead">Bienvenido a las simulaciones empresariales de 3DEXPERIENCE Game. Su acceso a los simuladores se realiza mediante su nombre de usuario y contraseña.</p>
                        <p className="lead">En todas las interfaces de simulación, realizaremos un seguimiento único para conocer su desempeño y desarrollo a lo largo de la actividad.</p>
                        <p className="lead">Creemos que la experiencia con el simulador consolida conceptos y relaciones, equilibra habilidades blandas y duras y prepara profesionales para un mundo cambiante.</p>
                        <p className="lead">Aquí podemos mejorar nuestras decisiones, podemos repensar nuestras acciones, rehacer escenarios, probar hipótesis y reevaluar. </p>
                        <p className="lead">El protagonismo y la rendición de cuentas son fundamentales, las opiniones están marcadas por la pluralidad, el ambiente es dinámico y lleno de oportunidades. </p>
                        <p className="lead">Aprovecha este reto y ante la duda escribe a <a href="mailto:suporte@compsim.com.br">suporte@compsim.com.br</a>. </p>

                    </div>
                </div>
                <div className="col-sm">
                    <div>
                        <Panels panels={[
                            {
                                name: "Ya tengo una cuenta",
                                content: <LoginWithEmailPassword onRegister={() => setPanel(1)} onForgotPassword={() => setPanel(2)} />
                            },
                            {
                                name: "No tengo cuenta",
                                content: <Register />
                            }
                            ,
                            {
                                name: "Olvidé la contraseña",
                                content: <PasswordResetEmail />,
                                visible: false
                            }
                        ]}
                            selected={panel}
                            onSelect={setPanel} />

                    </div>
                </div>
            </div>
        </div>
    );
}