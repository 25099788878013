import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ActionSuspense from "../../components/ActionSuspense";
import Panels from "../../components/Panels";
import enrollmentDatabase from "../../database/EnrollmentDatabase";
import { allQuestions } from "../../database/QuestionDatabase";
import { allTracks } from "../../database/TrackDatabase";
import { IEnrollment } from "../../types/EnrollmentInterface";
import { IQuestion } from "../../types/QuestionInterface";
import { ITrack } from "../../types/TrackInterface";
import usePromise from "../../utils/usePromise";

function getExtensionTextDistribution(
  q: IQuestion,
  enrollments: IEnrollment[]
) {
  const nameMap: Record<string, number> = {};
  enrollments.forEach((e) =>
    e.answers.forEach((a) => {
      if (a.questionId === q.questionId && a.extensionText) {
        const entry = (nameMap[a.extensionText] ?? 0) + 1;
        nameMap[a.extensionText] = entry;
      }
    })
  );
  return nameMap;
}

function DistributionTable(distrib: Record<string, number>, sum: number) {
  const keys = Object.keys(distrib);
  return keys.length ? (
    <table className="table">
      <tbody>
        {keys.map((key) => (
          <tr key={key}>
            <td>{key}</td>
            <td>{distrib[key]}</td>
            <td style={{ width: "200px", paddingTop: "13px" }}>
              <div
                className="progress"
                title={`${((100 * distrib[key]) / sum).toFixed(1)}%`}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${((100 * distrib[key]) / sum).toFixed(1)}%`,
                  }}
                  aria-valuenow={(100 * distrib[key]) / sum}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    "Nenhuma"
  );
}

function getExtensionItemsDistribution(
  q: IQuestion,
  enrollments: IEnrollment[]
) {
  const nameMap: Record<string, number> = {};
  q.options.forEach((opt) => {
    opt.extension?.items?.forEach((item) => {
      nameMap[item.text] = 0;
    });
  });
  enrollments.forEach((e) =>
    e.answers.forEach((a) => {
      if (a.questionId === q.questionId) {
        a.extensionItems?.forEach((ei) => {
          nameMap[ei]++;
        });
      }
    })
  );
  return nameMap;
}

function getOptionsDistribution(q: IQuestion, enrollments: IEnrollment[]) {
  const options = q.options.reduce((opts, item) => {
    opts[item.text] = 0;
    return opts;
  }, {} as Record<string, number>);
  enrollments.forEach((e) =>
    e.answers.forEach((a) => {
      if (a.questionId === q.questionId) {
        const opt = q.options[a.optionNumber];
        options[opt.text]++;
      }
    })
  );
  return options;
}

function renderQuestion(q: IQuestion, enrollments: IEnrollment[]) {
  const distrib = getOptionsDistribution(q, enrollments);
  const sum = enrollments.length;
  return (
    <React.Fragment key={q.questionId}>
      <div className="row">
        <div className="col-sm-4">
          <h3>{q.characterName}</h3>
          <p>{q.context.join(" ")}</p>
          <h4>{q.content.join(" ")}</h4>
        </div>
        <div className="col-sm-8">
          {DistributionTable(distrib, sum)}
          <h4>Respuestas de la plataforma</h4>
          <div>
            {DistributionTable(
              getExtensionTextDistribution(q, enrollments),
              sum
            )}
          </div>
          <hr />
          <h4>Extensiones de respuesta</h4>
          {DistributionTable(
            getExtensionItemsDistribution(q, enrollments),
            sum
          )}
        </div>
      </div>
      <hr />
    </React.Fragment>
  );
}

function AllQuestions(enrollments: IEnrollment[]) {
  return allQuestions.map((q) => renderQuestion(q, enrollments));
}

function TrackQuestions(track: ITrack, enrollments: IEnrollment[]) {
  const questions = allQuestions.filter(
    (q) => track.questions.indexOf(q.questionId) >= 0
  );
  return questions.map((q) =>
    renderQuestion(
      q,
      enrollments.filter((e) => e.trackName === track.title)
    )
  );
}

export default function QuestionsReport() {
  const { loading, error, result } = usePromise(
    enrollmentDatabase.getSimulationEnrollments("3ds")
  );
  const [selected, setSelected] = useState(0);

  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/reports">Informes</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Respuestas
          </li>
        </ol>
      </nav>
      <h1 className="display-1">Distribución de Respuestas</h1>
      <ActionSuspense loading={loading} error={error}>
        {result ? (
          <Panels
            selected={selected}
            panels={[
              { name: "Todas las respuestas", content: AllQuestions(result) },
              ...allTracks.map((t) => ({
                name: t.title,
                content: TrackQuestions(t, result),
              })),
            ]}
            onSelect={setSelected}
          />
        ) : (
          <div className="alert alert-danger">Entradas no encontradas.</div>
        )}
      </ActionSuspense>
    </div>
  );
}
