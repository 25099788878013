import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q3",
  context: [
    "Nuestro mejor diseñador se muda al Nordeste y sé que no podemos perderlo ya que es vital para el día a día de nuestra empresa.",
    "Como saben, nuestra política de trabajo remoto ya se aplica en algunas áreas, pero nunca tuvimos personas del equipo de ingeniería en esta situación.",
  ],
  content: [
    "¿Cómo podemos mantenerlo en el equipo aunque sea remotamente?",
  ],
  options: [
    {
      text: "Desafortunadamente, será inviable ya que nuestros archivos de proyecto se almacenan en una infraestructura local que no permite el acceso remoto.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Sobre el trabajo remoto, la tecnología a lo largo de los años ha traído nuevas oportunidades y diferentes formas de pensar sobre el tema. La pérdida de un empleado tan importante que tiene buenos resultados puede afectar la productividad de la empresa.",
    },
    {
      text: "Podemos ponerlo en un proyecto que no tiene colaboración con nadie del equipo y usamos una herramienta para compartir archivos (ej: WeTransfer, Google Drive...).",
      xValue: 0.25,
      yValue: 0.5,
      feedbackContent:
        "La colaboración entre los equipos y la gerencia es clave para lograr los objetivos y tener un flujo de trabajo productivo. Y tener herramientas de comunicación y uso compartido de archivos desvinculadas con su PLM como WeTransfer, Google Drive puede generar conflictos futuros.",
    },
    {
      text: "La única forma de hacer esto actualmente es trabajar a través de VPN para acceder al servidor.",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "Trabajar a través de VPN, acceder al servidor de forma remota, puede ser una buena idea inicialmente, pero a la larga, las grandes interrupciones y la lentitud de la conexión pueden terminar dejando el flujo de trabajo no fluido.",
    },
    {
      text: "Usamos una plataforma de colaboración en la nube y esto resuelve el desafío de tener a nuestro diseñador de forma remota.",
      xValue: 0.75,
      yValue: 0.75,
      extension: {
        type: "OpenAnswer",
        title: "¿Qué plataforma usas?",
      },
      feedbackContent:
        "Al poner una plataforma de colaboración en la nube a disposición de su diseñador, le permite estar en cualquier parte del mundo, usar documentos y dibujos actualizados y en tiempo real, lo que le permite continuar entregando un gran trabajo, así como en persona.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-elevator.jpg",
  characterName: "Leonor - Gerente de RH",
  sceneNumber: 8,
} as IQuestion;
