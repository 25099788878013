import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q7",
  context: [
    "Aquí, en el comité de Sostenibilidad, recibimos una consulta de un cliente sobre nuestra visión de certificaciones como la ISO 14.000 y acciones internas encaminadas a reducir el impacto ambiental.",
  ],
  content: [
    "Por esta razón, estoy recopilando con todas las áreas cuáles son sus iniciativas para este tema.",
  ],
  options: [
    {
      text: "Desafortunadamente, hasta ahora la empresa no ha podido medir nuestro impacto ambiental y necesitaremos que el Comité aborde este problema.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Es de suma importancia cumplir con la legislación y las certificaciones de calidad estandarizadas a nivel mundial. La sustentabilidad es una agenda muy presente y sería importante medir el impacto que tiene la empresa, para buscar siempre la reducción de la contaminación, brindando equilibrio y protección al medio ambiente.",
    },
    {
      text: "En mi departamento podemos medir el impacto que estamos teniendo, pero sé que muchas áreas no pueden medir lo mismo.",
      xValue: 0.25,
      yValue: 0.5,
      extension: {
        type: "OpenAnswer",
        title:
          "¿Podrías contarme un poco más sobre cómo haces este proceso de medición de impacto ambiental en tu zona? ¿Qué tipo de datos e informes puede extraer?",
      },
      feedbackContent:
        "Es interesante implementar el control de impacto ambiental dentro de algunos sectores de la empresa, pero lo ideal siempre es buscar trabajar de manera sustentable en todas las áreas y durante todo el proceso de desarrollo del producto.",
    },
    {
      text: "Podemos medir el impacto desde la extracción de materia prima, residuos, etc., hasta la entrega final del producto.",
      xValue: 0.75,
      yValue: 0.75,
      extension: {
        type: "OpenAnswer",
        title:
          "¿Podrías contarme un poco más sobre cómo haces este proceso de medición de impacto ambiental en tu zona? ¿Qué tipo de datos e informes puede extraer?",
      },
      feedbackContent:
        "Medir el impacto ambiental y ser una empresa certificada ISO 14.000 aporta credibilidad a otras empresas que pueden ser posibles socios, a los clientes y ayuda a que el planeta se convierta en un lugar menos contaminado.",
    },
  ],
  characterName: "Marcondes - Ingeñiero ambiental",
  sceneNumber: 4,
} as IQuestion;
