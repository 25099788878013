export default function MediaProdutividadeMediaInovacao(){
    return (
      <div>
        <h1 className="display-1">Productividad promedio, Innovación promedio</h1>
        <p className="lead">
        Según las decisiones tomadas durante el juego, su empresa ha perdido 
        algunas empresas debido a la dificultad para satisfacer todas las demandas actuales 
        del mercado en términos de productividad. <br />
        Otro punto, la baja inversión 
        en la innovación de sus procesos, indicados por sus acciones durante el juego, 
        plantea la posibilidad de que sus costos actuales para mantener este alto 
        la productividad puede ser un poco más alta de lo que debería ser. <br />
        Además, la entrada en nuevos mercados puede estar vinculada a 
        capacidad de innovación y la habilidad de producir de acuerdo a las demandas de sus clientes.
        </p>
      </div>
    );
}