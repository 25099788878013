import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q4",
  context: [
    "¡Tenemos una pregunta desafiante! Nuestros proveedores de servicios de ingeniería trabajan con diferentes software CAD 3D.",
    "Sin embargo, necesitamos que todos los elementos de cada proyecto estén en el mismo entorno, formando así un solo producto.",
  ],
  content: ["¿Cómo estás afrontando actualmente esta situación?",
  ],
  options: [
    {
      text: "Nuestros proveedores solo envían los diseños en PDF 2D.",
      xValue: 0.25,
      yValue: 0,
      feedbackContent:
        "En cuanto a la gestión de artículos de proveedores, trabajar con archivos PDF 2D compartidos termina restringiendo la colaboración y la visualización en tiempo real del progreso del proyecto.",
    },
    {
      text: "Actualmente requiero que mis proveedores se adapten y trabajen con el mismo software que yo.",
      xValue: 0.75,
      yValue: 0.25,
      feedbackContent:
        "La segregación de proveedores que trabajan solo con el mismo software puede no ser la opción más viable, ya que actualmente existen otras formas de compartir archivos que usan el formato nativo.",
    },
    {
      text: "A los proveedores que no siguen el mismo estándar, les pedimos que envíen sus archivos en formatos 3D genéricos (STEP, IGES y etc...). ¡No es lo ideal, pero está funcionando!",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "Para tratar con proveedores que no siguen el mismo estándar, es fundamental extraer la mayor cantidad de información posible, pero los archivos STEP pierden características al compartir CAD 3D, como la iluminación y la configuración de la cámara.",
    },
    {
      text: "En nuestro proceso, podemos recibir estos archivos en sus formatos nativos y usarlos para crear virtualmente el producto final, sin pérdida de información.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title: "¿Qué sistema CAD usas para esto?",
      },
      feedbackContent:
        "La composición de un producto no siempre proviene de piezas diseñadas en un solo software CAD, y trabajar con una herramienta que le permita usar todo tipo de extensiones en su forma nativa seguramente abrirá una gama de proveedores y optimizará el tiempo del proyecto, por lo que en consecuencia se convierte en la mejor opción.",
    },
  ],
  backgroundImage: "/img/cafeteria/bg-conselho.png",
  characterName: "Melissa - Gerente de Ingeniería",
  sceneNumber: 5,
} as IQuestion;
