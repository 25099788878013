import AppLayout from "../../components/AppLayout";

export default function About() {
    return (
        <AppLayout justifyContent="flex-start" alignItems="flex-start">
            <div className="container">
                <hr />
                <h1>Acerca del juego 3DEXPERIENCE</h1>
                <hr />
                <p className="lead">
                El juego 3DEXPERIENCE tiene como objetivo ayudar a los jugadores a comprender el actual
                 escenario en el que se encuentran en su día a día dentro de su empresa, a través de
                 de una actividad recreativa.
                 A través del Juego 3DEXPERIENCE, el jugador tendrá un análisis macro de cómo funciona su empresa
                 es en términos de Productividad e Innovación y el diagnóstico completo ayudará
                 el equipo y los socios de DASSAULT SYSTEMES para delinear los puntos de mejora y cómo
                 potenciar aún más las cualidades que ya existen en la empresa.
                 ¡Buen juego!
                </p>
                <hr />
                <div className="row g-5">
                    <div className="col-md-6">
                        <h2>Realización</h2>
                        <p>Este juego es un proyecto colaborativo entre DASSAULT SYSTEMES y Compsim Brasil.</p>
                        <ul className="icon-list ps-0">
                            <li className="text-muted d-flex align-items-start mb-1"></li>
                        </ul>
                    </div>

                    <div className="col-md-6">
                        <h2>Ayuda</h2>
                        <p>Para obtener ayuda para este simulador tenemos los siguientes puntos de contacto:</p>
                        <ul className="icon-list ps-0">
                            <li className="d-flex align-items-start mb-1"><a href="mailto:suporte@compsim.com.br">Compatibilidad con Compsim</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}