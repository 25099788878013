import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q8-e",
  context: [
    "Tenemos un proyecto para reducir los costos y el tiempo de servicio para las actividades de mantenimiento. Dos puntos críticos para este problema son el alto costo del transporte de profesionales calificados para atender las",
    "llamadas de mantenimiento básico, así como la creación y traducción a diferentes idiomas de manuales impresos con procedimientos de mantenimiento.",
  ],
  content: [
    "¿Existe actualmente alguna iniciativa para optimizar la creación de procedimientos de mantenimiento que nos ayuden a optimizar los puntos mencionados?",
  ],
  options: [
    {
      text: "No, hoy todos los procedimientos de mantenimiento los creamos de forma tradicional, es decir, se imprimen con fotos de los equipos y traducimos los documentos a cada idioma solicitado.",
      xValue: 0,
      yValue: 0,
      feedbackContent:
        "Dentro de la propuesta de optimización, no tener documentación y propiedad intelectual disponible en la nube para los empleados interesados ​​dificulta la realización del trabajo, y mantener manuales impresos implica certificaciones en medio ambiente, sustentabilidad, lo que puede abrir muchas oportunidades en el futuro.",
    },
    {
      text: "Estamos adoptando la sustitución de fotos por imágenes de proyectos 3D, esto agiliza un poco la creación de documentos.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "La iniciativa de reemplazar los proyectos 3D con imágenes aún es un proceso enyesado, la transferencia de datos aún siendo manual tiene un gran impacto en la productividad y la disponibilidad de un documento tan importante.",
    },
    {
      text: "Usamos una herramienta específica que aprovecha los datos de ingeniería 3D, esto agiliza mucho la creación y actualización de documentos, pero la colaboración de estos datos sigue siendo una preocupación.",
      xValue: 0.5,
      yValue: 0.75,
      feedbackContent:
        "La iniciativa de aprovechar los datos de ingeniería agiliza en gran medida el flujo de trabajo, pero si otros colaboradores no pueden ayudar a crear, visualizar en tiempo real o compartir es burocrático, sigue siendo un cuello de botella en el desarrollo de productos.",
    },
    {
      text: "Utilizamos una herramienta específica que aprovecha los datos de ingeniería 3D, lo que agiliza enormemente la creación y actualización de documentos además de permitir la creación de procedimientos interactivos, reduciendo la necesidad de manuales impresos. Todos estos datos se integran en nuestro entorno de nube, permitiendo el consumo y la colaboración de una forma mucho más rápida y dinámica.",
      xValue: 0.75,
      yValue: 1,
      extension: {
        type: "MultipleChoice",
        title: "",
        items: [
          {
            text: "Simulación de diseño",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Simulación de Procesos",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Gestión de lista de materiales de fabricación (MBOM)",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Simulación de ergonomía",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Programación y simulación de robots",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Programación y simulación de máquinas de mecanizado",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Instrucciones de montaje interactivas",
            xValue: 0.01,
            yValue: 0.0,
          },
          {
            text: "Entrenamiento de Equipos usando Realidad Virtual",
            xValue: 0.01,
            yValue: 0.0,
          },
        ],
      },
      feedbackContent:
        "La iniciativa de aprovechar los datos de Ingeniería, poder acceder en todo momento a esta información, visualizar y colaborar en tiempo real, tener un repositorio en la nube con todos estos componentes, es el camino ideal para aquellos que quieren ser más productivo.",
    },
  ],
  characterName: "Cristiane - Controller",
  sceneNumber: 7,
} as IQuestion;
