export default function BaixaProdutividadeBaixaInovacao(){
    return (
      <div>
        <h1 className="display-1">Baixa Produtividade, Baixa Inovação</h1>
        <p className="lead">
        Baseado nas decisões tomadas durante o jogo, sua empresa tem perdido 
        diversos negócios por dificuldade em atender todas as demandas atuais 
        do mercado em termos de produtividade. <br />
        Outro ponto é que a decisão de 
        não investir na inovação de seus processos, indicada pelas suas ações 
        durante jogo, levanta a possibilidade de que seus custos atuais para 
        manter baixa produtividade podem estar bem acima do que deveriam. <br />
        Além disso, a entrada em novos mercados pode estar vinculada a capacidade 
        de inovação e a capacidade de produzir conforme as demandas dos seus clientes.
        </p>
      </div>
    );
}