export default function AltaProdutividadeBaixaInovacao(){
    return (
      <div>
        <h1 className="display-1">Alta Produtividade, Baixa Inovação</h1>
        <p className="lead">
          Baseado nas decisões tomadas durante o jogo, sua empresa tem 
          atendido as demandas atuais do mercado em termos de produtividade. <br />
          Porém, a decisão de não investir na inovação de seus processos, 
          indicada pelas suas ações durante jogo, levanta a possibilidade de 
          que seus custos atuais para manter essa alta produtividade podem 
          estar bem acima do que deveriam. <br />
          Além disso, a entrada em novos 
          mercados pode estar vinculada a capacidade de inovação de seus processos.
          </p>
      </div>
    );
}