import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import AppLayout from "../../components/AppLayout";
import TrackSelect from "./TrackSelect";

class IntroState {
  current = 0;
  get hasPrevious() {
    return this.current > 0;
  }
  get hasNext() {
    return this.current < 3;
  }
  next() {
    this.current++;
  }
  previous() {
    this.current--;
  }
  constructor() {
    makeAutoObservable(this);
  }
}
const introState = new IntroState();

const IntroItem = observer((props: { children: ReactNode }) => {
  return (
    <div className="centered-blurred-message fade-in">
      {props.children}
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        {introState.hasPrevious ? (
          <button
            className="btn btn-secondary"
            onClick={() => introState.previous()}
          >
            &laquo; Regresar
          </button>
        ) : null}

        <button className="btn btn-primary" onClick={() => introState.next()}>
          Avance &raquo;
        </button>
      </div>
    </div>
  );
});

function Intro1() {
  return (
    <IntroItem>
      <h1>3DEXPERIENCE Game</h1>
      <hr />
      <div>
        <p className="lead">
          Su empresa, después de estos años de Pandemia, ha estado buscando
          formas de crecer y recuperar impulso y el escenario ahora es mucho más
          desafiante.
        </p>
        <hr />
      </div>
      <div>
        <p className="lead">
          Su Empresa se enfrenta a un cambio en el perfil de sus clientes (con
          la pérdida de algunos clientes recurrentes y la aparición de nuevas
          oportunidades).
        </p>
        <hr />
      </div>
    </IntroItem>
  );
}

function Intro2() {
  return (
    <IntroItem>
      <div>
        <p className="lead">
          El impacto financiero de todos los involucrados fue grande y temas
          como: reducción de costos, agilidad y flexibilidad en la forma de
          trabajar se vuelven puntos importantes.
        </p>
        <hr />
      </div>
      <div>
        <p className="lead">
          El mercado ha estado demandando nuevos productos y servicios y su
          departamento estará cada vez más presionado para cumplir con estas
          solicitudes.
        </p>
        <hr />
      </div>
      <div>
        <p className="lead">
          Algunos clientes de geografías a las que nunca antes habían accedido
          comenzaron a contactar a sua Empresa para saber si usted puede
          atenderlos.
        </p>
        <hr />
      </div>
    </IntroItem>
  );
}

function Intro3() {
  return (
    <IntroItem>
      <div>
        <p className="lead">
          Teniendo en cuenta este desafío, responde as las preguntas que
          aparecerán siguiendo el perfil actual de tu empresa y la forma en que
          reaccionas a los desafíos.
        </p>
        <hr />
      </div>
      <div>
        <p className="lead">
          No te plantees cómo te gustaría que se hiciera, sino cómo se hace
          actualmente.
        </p>
        <hr />
      </div>
      <div>
        <p className="lead">
          Continúe y elija entre el equipo de ingeniería o el de fabricación
          para continuar.
        </p>
        <hr />
      </div>
    </IntroItem>
  );
}

const IntroductionContent = observer(() => {
  switch (introState.current) {
    case 0:
      return <Intro1 />;
    case 1:
      return <Intro2 />;
    case 2:
      return <Intro3 />;
    default:
      return <TrackSelect />;
  }
});

export default function Introduction() {
  return (
    <AppLayout
      backgroundImg="/img/bg-office.jpg"
      justifyContent="center"
      alignItems="center"
    >
      <IntroductionContent />
    </AppLayout>
  );
}
