import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q2",
  characterName: "Renata - Diretora Comercial",
  sceneNumber: 3,
  context: [
    "Surgió una oportunidad en un cliente en Europa que nos obliga a verificar cómo lo hacemos para tener visibilidad en tiempo real de la evolución de la gestión de proyectos.",
  ],
  content: ["¿Cómo podemos cumplir con este requisito?"],
  options: [
    {
      text: "En nuestra política de trabajo buscamos el alcance del proyecto, creamos el producto y al final del trabajo lo enviamos al cliente para su aprobación final, si hay algún ajuste lo podemos hacer.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "Para que el cliente tenga visibilidad en tiempo real, es crucial que haya interacción con él durante todo el proceso de creación y desarrollo del producto, la validación y la comunicación deben ser constantes para evitar el retrabajo.",
    },
    {
      text: "Em tempo real não podemos fazer, porém, podemos enviar periodicamente via e-mail o status do projeto.",
      xValue: 0.5,
      yValue: 0.25,
      feedbackContent:
        "Para que el cliente tenga visibilidad en tiempo real, es crucial que haya interacción con él durante todo el proceso de creación y desarrollo del producto, la validación y la comunicación deben ser constantes para evitar el retrabajo.",
    },
    {
      text: "Podemos hablar con el área de TI para darle acceso al cliente a nuestro servidor y liberar una carpeta con los PDF del proyecto.",
      xValue: 0.75,
      yValue: 0.5,
      feedbackContent:
        "Para que el cliente tenga visibilidad, dar acceso a una carpeta en el servidor es una alternativa válida, pero aún considerada enyesada, la visibilidad en tiempo real aumenta la asertividad y las solicitudes del cliente serán atendidas más rápido, como cambios en el proyecto.",
    },
    {
      text: "Daremos acceso a nuestro sistema de gestión de proyectos que está en la nube y desde allí el cliente tendrá una visión en tiempo real de su proyecto.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title: "¿Qué herramienta usas? (Escribe)",
      },
      feedbackContent:
        "Dar acceso al cliente al sistema de gestión en la nube se convierte en la opción que consigue alcanzar un inmenso nivel de satisfacción, siendo accesible para todos y en todo momento, posibilitando la visualización del proyecto y el feedback instantáneo.",
    },
  ],
} as IQuestion;
