import { observer } from "mobx-react-lite";
import ActionSuspense from "../../components/ActionSuspense";
import { authenticationState, resendVerificationEmail } from "../../services/Authentication";


export default observer(function VerifyEmail() {

    return (
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div>
              <h1 style={{ marginBottom: "20px" }}>¡Bienvenido!</h1>
            </div>
          </div>
          <div className="col-sm">
            <p>Todavía necesita verificar su dirección de correo electrónico.</p>
            <p>Vaya a su bandeja de entrada y haga clic en el enlace de validación.</p>
            <p>¿No recibiste el enlace?</p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => window.location.reload()}
            >
              Actualizar
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-secondary"
              onClick={resendVerificationEmail}
            >
              Reenviar
            </button>
            <ActionSuspense
              loading={authenticationState.authLoading}
              error={authenticationState.authError}
              success={authenticationState.authSuccess}
            />
          </div>
        </div>
      </div>
    );
});