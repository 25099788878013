import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q9-e",
  context: [
    "Pudimos cerrar el contrato con un cliente de EE. UU. que trae un nuevo producto que requerirá un nuevo diseño de nuestra línea de producción, fabricación de herramientas,",
    "así como capacitación del equipo de producción y ensamblaje.",
  ],
  content: [
    "Tendremos que ser ágiles para implementar estos cambios en nuestra fábrica. ¿Cómo solemos hacer estos ajustes para introducir nuevos procesos de producción?",
  ],
  options: [
    {
      text: "Mantenemos el diseño actual porque siempre hemos hecho nuestros productos de esa manera.",
      xValue: 0.25,
      yValue: 0,
      feedbackContent:
        "Para la implementación de nuevos procesos de producción, es importante tener siempre cierta flexibilidad y tratar de mantener un diseño que sea fácil de adaptar en caso de que haya una oportunidad de negocio que no se pueda desaprovechar. Mantener el diseño único y estándar puede no ser funcional, ya que habrá conflictos y, principalmente, necesitará un plazo de entrega más largo para un detalle de planificación.",
    },
    {
      text: "No tenemos soluciones para simular estos escenarios y normalmente realizamos simulaciones y ajustes durante la producción real en fábrica.",
      xValue: 0.25,
      yValue: 0.5,
      feedbackContent:
        "Para la implementación de nuevos procesos productivos, realizar ajustes durante la operación y producción puede ser extremadamente riesgoso, ya que la planificación es fundamental para mitigar riesgos y establecer plazos. Contratar una consultoría para cada cambio necesario puede ser muy costoso a la larga y ya existen herramientas capaces de construir automáticamente el mejor diseño para tal actividad.",
    },
    {
      text: "Podemos contratar una consultora para que nos apoye en las simulaciones y definiciones del mejor escenario para la producción de este nuevo producto, pero este costo no fue considerado en la propuesta.",
      xValue: 0.5,
      yValue: 0.5,
      feedbackContent:
        "Para la implementación de nuevos procesos productivos, la subcontratación de costos puede convertirse en una buena alternativa, pero con una herramienta de gestión de layout, el tiempo de respuesta a los clientes y la planificación se vuelve más rápido, y la empresa sería más competitiva al poder negociar un valor atractivo. sin tener que pagar por la consultoría por adelantado.",
    },
    {
      text: "Disponemos de una solución que nos permite simular diferentes escenarios de la planta y llegar al mejor layout, proceso e idoneidad del equipo de fabricación y montaje antes de iniciar la producción.",
      xValue: 0.75,
      yValue: 1,
      feedbackContent:
        "Para la implementación de nuevos procesos productivos, siempre es bueno contar con una herramienta que automatice y prediga el mejor layout entre los recursos disponibles, aliviando el tiempo de planificación, agilizando todo el proceso de adaptación al nuevo producto.",
    },
  ],
  characterName: "Renata - Director Comercial",
} as IQuestion;
