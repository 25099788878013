import { useState } from "react";
import { NavLink } from "react-router-dom";
import Echart from "../../components/Echart";
import Nav, { NavItem } from "../../components/Nav";
import NineBox from "../../components/NineBox";
import { allQuestions } from "../../database/QuestionDatabase";
import { allTracks } from "../../database/TrackDatabase";
import {
  getNineBoxQuadrant,
  NineBoxQuadrant,
  NINE_BOX,
} from "../../services/NineBoxAnalysis";
import { IQuestion } from "../../types/QuestionInterface";

const SAMPLE_SIZE = 2000;

function createRandomAnswer(questions: IQuestion[]) {
  const X: number[] = [];
  const Y: number[] = [];
  for (const q of questions) {
    const random_option = Math.floor(Math.random() * q.options.length);
    const option = q.options[random_option];
    X.push(option.xValue ?? 0);
    Y.push(option.yValue ?? 0);
  }
  const avg_x = X.reduce((s, v) => s + v, 0.0) / questions.length;
  const avg_y = Y.reduce((s, v) => s + v, 0.0) / questions.length;
  return [avg_x, avg_y];
}

function createDataPoints(size: number, trackIndex: number): number[][] {
  const allPairs: number[][] = [];
  const track = allTracks[trackIndex];
  const questions = track.questions.map((id) =>
    allQuestions.find((q) => q.questionId === id)
  ) as IQuestion[];
  for (let i = 0; i < size; i++) {
    allPairs.push(createRandomAnswer(questions));
  }
  return allPairs;
}

function DistributionTable(props: { distribution: Record<string, number> }) {
  const distribution = props.distribution;
  const total = Object.values(distribution).reduce(
    (s, v) => s + Number(v),
    0.0
  );
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Clase</th>
          <th>La cantidad</th>
          <th>Porcentaje</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(distribution).map((name, i) => {
          const value = distribution[name] || 0;
          const pct = (100.0 * value) / total;
          return (
            <tr key={i}>
              <td>{name}</td>
              <td>{value}</td>
              <td>{pct} %</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default function PossibleAnswerDistribution() {
  const [sampleSize, setSampleSize] = useState(SAMPLE_SIZE);
  const [track, setTrack] = useState(0);

  const points = createDataPoints(sampleSize, track);
  const distribution: Record<string, number> = {};
  for (let q of NINE_BOX) distribution[q.name] = 0;
  for (let point of points) {
    const q: NineBoxQuadrant | undefined = getNineBoxQuadrant(
      point[0],
      point[1]
    );
    const key = q?.name || "undefined";
    const current = distribution[key] || 0;
    distribution[key] = current + 1;
  }
  return (
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="/reports">Informes</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Simulación de posibles Respuestas
          </li>
        </ol>
      </nav>
      <h1>Simulación Monte Carlo de posibles respuestas</h1>
      <p className="lead">
        El método Monte Carlo se ha utilizado durante mucho tiempo como una
        forma de obtener aproximaciones numéricas de funciones complejas donde
        no es factible, o incluso es imposible obtener una solución analítica, o
        al menos determinista.
      </p>
      <p>
        En principio, los métodos de Monte Carlo se pueden utilizar para
        resolver cualquier problema con una interpretación probabilística. Por
        la Ley de Números grandes, integrales descritas por el valor esperado de
        algunos La variable aleatoria se puede aproximar obteniendo la media
        empírica de muestras independientes de variables. Cuando la distribución
        de se parametriza la probabilidad de la variable, normalmente la muestra
        generador de cadena de Markov Monte Carlo (MCMC), teniendo así, que en
        el límite, las muestras generadas serán muestras de la distribución
        deseada.
      </p>
      <p>
        En este informe usamos{" "}
        <input
          className="form-control"
          type="number"
          value={sampleSize}
          onChange={(e) => setSampleSize(e.target.valueAsNumber)}
          style={{ width: "80px", display: "inline-block" }}
        />{" "}
        respuestas aleatorias para estimar la distribución en los cuestionarios.
        LA cada página, seleccionamos aleatoriamente la pista y calculamos la
        distribución de respuestas.
      </p>

      <p>
        A continuación tenemos el resultado de la distribución, para ambas
        pistas de preguntas:
      </p>
      <Nav
        items={allTracks.map(
          (t, i) =>
            ({
              name: t.title,
              onClick: () => setTrack(i),
            } as NavItem)
        )}
        selected={track}
      />
      <NineBox points={points} />
      <hr />
      <Echart
        height={500}
        options={{
          xAxis: {
            type: "category",
            data: Object.keys(distribution),
            axisLabel: {
              show: true,
              interval: 0,
              rotate: 60,
            },
          },
          yAxis: {
            type: "value",
          },
          grid: {
            bottom: 215,
            left: 50,
            right: 1,
          },
          tooltip: {},
          series: [
            {
              data: Object.values(distribution),
              type: "bar",
            },
          ],
        }}
      />
      <hr />
      <DistributionTable distribution={distribution} />
    </div>
  );
}
