import { IQuestion } from "../../types/QuestionInterface";

export default {
  questionId: "3ds_q12",
  context: [
    "Como todos saben, los cambios recientes en la demanda han cambiado mucho nuestra combinación de producción.",
    "Tuvimos un aumento en la frecuencia de nuevos productos y/o variaciones de los mismos y, consecuentemente, en los costos de operación por el impacto en los tiempos de proceso y la no calidad.",
    "Necesitamos mejorar nuestra capacidad para comprender, identificar y reaccionar ante los factores que afectan nuestras desviaciones operativas para garantizar nuestro nivel de servicio.",
  ],
  content: [
    "¿Dónde tenemos espacio para mejorar la comunicación entre las áreas que apoyan LEAN dentro de nuestro proceso?",
  ],
  options: [
    {
      text: "Solo necesitamos revisar y mejorar nuestros métodos de gestión de la producción.",
      xValue: 0.25,
      yValue: 0.25,
      feedbackContent:
        "Para mejorar la comunicación y la comprensión de cómo debe comportarse la empresa, los métodos de gestión de producción como Six Sigma, Lean Manufacturing, PDCA requieren mucho tiempo y no son fáciles de implementar, tener un sistema que controle y proporcione indicadores de puntos de mejora es de extrema valor. para que sea constante y equivalente a la evolución de todas las áreas.",
    },
    {
      text: "Tenemos que integrar los diferentes métodos de gestión que soportan el Lean Manufacturing.",
      xValue: 0.5,
      yValue: 0.5,
      feedbackContent:
        "La inversión en integraciones y mejoras en el área de TI es una buena salida, pero existen sobrecostes que podrían mitigarse con tecnologías ya presentes en el mercado, como las herramientas de gestión en la nube, que no requieren ningún sobrecoste y no requieren la integración de diferentes métodos para apoyarse a sí mismo.",
    },
    {
      text: "Solo necesitamos implementar o mejorar nuestros sistemas de TI para proporcionar una mayor visibilidad de la causa raíz de las desviaciones y priorizar las acciones.",
      xValue: 0.75,
      yValue: 0.75,
      extension: {
        type: "OpenAnswer",
        title:
          "¿Se utiliza actualmente alguna herramienta informática para dar soporte a los sistemas de gestión de la producción?",
      },
      feedbackContent:
        "La inversión en integraciones y mejoras en el área de TI es una buena salida, pero existen sobrecostes que podrían mitigarse con tecnologías ya presentes en el mercado, como las herramientas de gestión en la nube, que no requieren ningún sobrecoste y no requieren la integración de diferentes métodos para apoyarse a sí mismo.",
    },
    {
      text: "Ya tenemos algunos sistemas para gestionar LEAN Manufacturing y necesitamos mejorar su uso.",
      xValue: 1,
      yValue: 1,
      extension: {
        type: "OpenAnswer",
        title:
          "¿Se utiliza actualmente alguna herramienta informática para dar soporte a los sistemas de gestión de la producción?",
      },
      feedbackContent:
        "El concepto de Lean Manufacturing, si se sigue, puede transformar una empresa en todas las áreas, junto con una herramienta en la que puede ser posible asignar toda la información relevante y tener una interacción de los equipos en favor de la reducción de costos, eliminando el desperdicio y manteniendo la calidad del producto.",
    },
  ],
  characterName: "Marcelo - Gerente de Calidad",
  sceneNumber: 9,
} as IQuestion;
